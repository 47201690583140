// ----------------------------------------------------------------------

const account = {
  displayName: 'Aucun Nom',
  email: 'demo@minimals.cc',
  photoURL: '/static/mock-images/avatars/avatar_default.jpg',
  role: 'rôle indeterminé'
};

export default account;
